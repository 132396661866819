import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Button from '../components/Button'
import Content from '../components/Content'
import Root from '../layouts/Root'

import config from '../config'

export default ({ data }) => {
  const { pagesMain, pagesMisc, branches, diensten, nieuws } = data

  const renderPageLinks = ({ edges }) =>
    edges.map(({ node }) => (
      <li key={node.id}>
        <Link to={node.path}>{node.context.title || 'notitle'}</Link>
      </li>
    ))

  const renderMarkdownLinks = ({ edges }) =>
    edges.map(({ node }) => (
      <li key={node.id}>
        <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
      </li>
    ))

  return (
    <Root>
      {/* prettier-ignore */}
      <Helmet>
          <title>{`Sitemap | ${config.title}`}</title>
          <meta property="og:title" content={`Sitemap | ${config.title}`} />
        </Helmet>

      <main>
        <Content
          title="Sitemap"
          cta={() =>
            // prettier-ignore
            <>
              <Button color="confirm" onClick={() => window.history.go(-1)} size="large">Terug</Button>
              <Button size="large" to="/contact/" type="link">Neem vrijblijvend contact met ons op</Button>
            </>
          }
        >
          <div>
            <h2>Algemeen</h2>
            <ul>
              <li>
                <Link to="/">W&R beveiliging - Homepage</Link>
                <br />
              </li>
              {renderPageLinks(pagesMain)}
            </ul>

            <ul>{renderMarkdownLinks(pagesMisc)}</ul>

            <h2>Branches</h2>
            <ul>{renderMarkdownLinks(branches)}</ul>

            <h2>Diensten</h2>
            <ul>{renderMarkdownLinks(diensten)}</ul>

            <h2>Nieuws</h2>
            <ul>{renderMarkdownLinks(nieuws)}</ul>
          </div>
        </Content>
      </main>
    </Root>
  )
}

export const query = graphql`
  query {
    pagesMain: allSitePage(
      filter: { context: { title: { nin: [null, "", "404", "Dev-404-page"] } } }
      sort: { fields: [context___title], order: ASC }
    ) {
      edges {
        node {
          id
          path
          context {
            title
            slug
          }
        }
      }
    }

    pagesMisc: allMarkdownRemark(
      filter: { fields: { type: { nin: ["branches", "diensten", "nieuws"] } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }

    branches: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/branches/.+/" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }

    diensten: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/diensten/.+/" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }

    nieuws: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/nieuws/.+/" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
